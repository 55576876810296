.hand {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.tiles {
    list-style: none;    
    display: flex;
    align-items: center;
    justify-content: center;
}

.letter {
    margin: 1rem;
    font-size: 42px;
    border-style: solid;
    border-width: 2px;
    text-align: center;
    width: 1.5ch;
    padding: 0.5ch;
    border-color: whitesmoke;
    background-color: antiquewhite;
    cursor: pointer;
    border-radius: 10px;

    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.tiles .letter.disabled {
    border-color: whitesmoke;
    border-style: dotted;
    background-color: grey;
}