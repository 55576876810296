html {
  background-color: black;
}

.App .background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -999;
  background-color:#001c0e;
  background-image: linear-gradient(30deg, #01180d 12%, transparent 12.5%, transparent 87%, #01180d 87.5%, #01180d),
  linear-gradient(150deg, #01180d 12%, transparent 12.5%, transparent 87%, #01180d 87.5%, #01180d),
  linear-gradient(30deg, #01180d 12%, transparent 12.5%, transparent 87%, #01180d 87.5%, #01180d),
  linear-gradient(150deg, #01180d 12%, transparent 12.5%, transparent 87%, #01180d 87.5%, #01180d),
  linear-gradient(60deg, #02311d 25%, transparent 25.5%, transparent 75%, #02311d 75%, #02311d),
  linear-gradient(60deg, #02311d 25%, transparent 25.5%, transparent 75%, #02311d 75%, #02311d);
  background-size:80px 140px;
  background-position: 0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px;
}

.hidden {
  display: none !important;
}

.App .postGame {
  background-color: aliceblue;
  position: absolute;
  padding: 1rem;
  top: 40vh;
  left: 40vw;
}