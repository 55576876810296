.board {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.board .currentWord {
    width: fit-content;
    display: flex;
    align-items: center;
}

.board .validStatus {
    padding: 1rem;
    border-radius: 5px;
}

.board .validStatus.Valid {
    background-color: green;
}

.board .validStatus.Invalid {
    background-color: red;
    color: white;
}

.board .words {
    position: absolute;
    right: 1ch;
    top: 1ch;
    background-color: antiquewhite;
    border-radius: 5px;
    list-style: none;
    font-size: 24px;
    padding: 0;
}

.board .words .wordContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.board .words .wordContainer * {
    padding: 1rem;
}

.board .words .wordContainer button {
    height: fit-content;
    padding: 10px;
    margin-right: 2ch;
}